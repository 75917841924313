import React, { createContext, useEffect, useState } from 'react';
import Amplify from 'aws-amplify';

const defaultContext = {
    user: null,
    setLoggedInUser: () => {},
    isLoggedIn: false,
    setIsLoggedIn: () => {}
}

export const AuthContext = createContext(defaultContext);

export default ({ children }) => {
    const [user, setLoggedInUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [expanded, setExpanded] = useState();

    useEffect(() => {
        const username = getCookie("username");
        const apiurl = getCookie("apiurl");
        const apiregion = getCookie("apiregion");
        const apikey = getCookie("apikey");
        console.log("auth context", username);
        if (username) {
            let myAppConfig = {
                aws_appsync_graphqlEndpoint: apiurl,
                aws_appsync_region: apiregion,
                aws_appsync_authenticationType: "API_KEY",
                aws_appsync_apiKey: apikey,
            }
            
            Amplify.configure(myAppConfig)
            setLoggedInUser({username});
            setIsLoggedIn(true);
        }
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {!isLoaded ?
                <span>Loading</span>
            : 
                <div>
                    <AuthContext.Provider value={{user, setLoggedInUser, isLoggedIn, setIsLoggedIn, expanded, setExpanded}}>{children}</AuthContext.Provider>
                </div>
            }
        </div>
    )
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }