import { Box, makeStyles, Typography, Grid, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton} from '@material-ui/core';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ButtonTemplate } from '../../components/button/button-template';
import moment from 'moment';
import { CreateSocial, UpdateSocial } from '../../lib/graphql-command/socials-command';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import { ProgressBar } from '../../components/progress/progress-bar';
import { RenameFile } from '../../lib/rename';
import MessageDialog from '../../components/dialog/message-dialog';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AlarmIcon from "@material-ui/icons/AddAlarm";

const useStyle = makeStyles(theme => ({
    error: {
        color: theme.palette.primary.main
    },
    File: {
        width: '100%'
    },
    input: {
        display: 'none'
    },
    progress: {
        width: '100%'
    }
}))

const bucket = process.env.GATSBY_BUCKET_IMAGE;

const NewSocial = () => {
    const classes = useStyle();
    const [social, setSocial] = useState({Title: "", Comment: "", File: null, PublishedAt: null, Status: 0})
    const [helperText, setHelperText] = useState({})
    const [progress, setProgress] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [messageDialog, setMessageDialog] = useState();

    const StatusOptions = [
        {
            key: 'published',
            text: 'Published',
            value: 1
        }, {
            key: 'pending',
            text: 'Pending',
            value: 0
        }
    ]

    const handleInput = (item, data) => {
        setSocial(input => ({
          ...input,
          [item]: data,
        }))
        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
    }

    const handleUpload = (file) => {
        if(!file) return;
        setProgress(null);
        if(file.size > 5000000) {
            setHelperText(helperText => ({
                ...helperText,
                File: 'Invalid file. Please upload jpg, jpeg, png or gif files with maximum size 5Mb'
            }));
            return
        }

        handleInput("File", null)

        Storage.configure({ bucket: bucket });
        Auth.currentCredentials()
        .then(data => {
            const key = `public/images/${data.identityId}/${RenameFile(file.name)}`;

            Storage.put(key, file, { contentType: file.type, customPrefix: { public: "" },
                progressCallback(progress) {
                    setProgress((100 * progress.loaded) / progress.total)
                },
            })
            .then(data => {
                handleInput("File", {
                    region: "us-west-1",
                    bucket: bucket,
                    key: data.key,
                })
            })
            .catch(err => console.log(err))
        });
    }

    const handleSubmit = () => {
        if(!social.Title) {
            setHelperText(helperText => ({
                ...helperText,
                Title: 'Title is required'
            }));
        }
        if(!social.Comment) {
            setHelperText(helperText => ({
                ...helperText,
                Comment: 'Comment is required'
            }));
        }
        if (!social.File) {
            setHelperText(helperText => ({
                ...helperText,
                File: 'Social image is required'
            }));
        }

        if (!social.PublishedAt) {
            setHelperText(helperText => ({
                ...helperText,
                PublishedAt: 'Publish on is required'
            }));
        }
        if(social.Title && social.Comment && social.File && social.PublishedAt) {
            setDisabled(true)
            API.graphql(graphqlOperation(CreateSocial, social))
            .then(result => {
                setMessageDialog('Social post created successfully')
                setDisabled(false)
            })
            .catch(err => console.log(err))
        }
    }

    console.log(social)

    return (
        <Box>
            <Typography variant='h4'>New Social</Typography>
            {social && <Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Title'
                        // placeholder='Add title'
                        defaultValue={social?.Title}
                        helperText={helperText.Title}
                        onChange={e => {
                            handleInput('Title', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <FormControl variant="outlined" size='small'>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={social.Status}
                            onChange={e => handleInput('Status', e.target.value)}
                        >
                            {StatusOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        multiline
                        rows={4}
                        label='Comment'
                        // placeholder='Add title'
                        defaultValue={social?.Comment}
                        helperText={helperText.Comment}
                        onChange={e => {
                            handleInput('Comment', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            inputVariant="outlined"
                            variant="inline"
                            label="Publish on"
                            color='primary'
                            size="small"
                            format="MMM dd, yyyy HH:MM"
                            ampm={false}
                            value={social.PublishedAt}
                            helperText={helperText.PublishedAt}
                            error={helperText.PublishedAt ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton>
                                        <AlarmIcon />
                                    </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(datetime) => {
                                handleInput('PublishedAt', moment(datetime).format("yyyy-MM-DD HH:MM:ss"))
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
                <Box py={5}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <label htmlFor='upload-file-social'>
                                <ButtonTemplate
                                    color="primary"
                                    variant='contained'
                                    borderradius="25px"
                                    size="small"
                                    component="span"
                                    padding="5px 35px"
                                    changetextcolor='#fff'
                                >
                                Upload Image
                                </ButtonTemplate>
                            </label>
                            <input
                                accept=".jpg,.jpeg,.png,.gif"
                                className={classes.input}
                                id='upload-file-social'
                                type="file"
                                onChange={e => handleUpload(e.target.files[0])}
                            />
                            <Box>
                                {helperText.File && <p className={classes.error}>{helperText.File}</p>}
                                {!social?.File?.key && progress && <ProgressBar className={classes.progress} value={progress} />}
                                {social?.File?.key &&
                                    <Box py={10}>
                                        <img src={`${process.env.GATSBY_CLOUDFRONT_DOMAIN}${social?.File?.key}`} alt="image" className={classes.File} />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>

                    <Box py={5}>
                        <ButtonTemplate
                            disabled={disabled}
                            borderradius='50px'
                            variant='outlined'
                            color='primary'
                            onClick={handleSubmit}
                        >
                            Save
                        </ButtonTemplate>
                    </Box>
                </Box>
            </Box>}
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
        </Box>
    )
}

export default NewSocial;