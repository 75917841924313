import React, { useContext, useState } from 'react';
import { AppBar, Backdrop, CircularProgress, Grid, IconButton, makeStyles, Toolbar, Typography, Avatar, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import { AuthContext } from '../../context/auth-context';
import DropdownButton from '../button/dropdown-button';
import { ButtonTemplate } from '../button/button-template';

const useStyle = makeStyles(theme => ({
  appbar: {
    paddingLeft: open => open ? '240px' : 0
  },
  text: {
    marginRight: '20px'
  },
  button: {
    cursor: 'pointer',
    marginRight: '20px'
  },
  avatarStyle: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(15),
    width: theme.spacing(15)
  },
}))

export const Appbar = ({ open, setOpen, drawerWidth }) => {
  const [loading, setLoading] = useState(false);
  const { appbar, button, text, avatarStyle } = useStyle(open, drawerWidth);
  const { user, setLoggedInUser, setIsLoggedIn } = useContext(AuthContext);

  const handleLogout = () => {
    setLoading(true);
    document.cookie = "username=;path=/";
    document.cookie = "apiurl=;path=/";
    document.cookie = "apiregion=;path=/";
    document.cookie = "apikey=;path=/";
    document.cookie = "mlnp_session=;path=/";
    setLoading(false);
    setLoggedInUser();
    setIsLoggedIn(false);
    navigate('/login');
  }

  return (
    <AppBar position='static' className={appbar}>
      <Toolbar variant='dense'>
        {!open && <IconButton
          color='inherit'
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>}
        <Grid container justify='flex-end'>
          {/* <Typography className={text}>{user?.username}</Typography>
          <Typography onClick={handleLogout} className={button}>Log out</Typography> */}
          <DropdownButton
            label={
                <Box>
                  <Grid container>
                    <Grid item>
                        <Typography variant='body1' style={{color: '#fff'}}>
                            <b>{user ? `${user.username} ` : null}</b>
                        </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={avatarStyle} />
                    </Grid>
                  </Grid>
                </Box>
            }
            dropdowncontent={
                <Box py={5}>
                    <Box py={5} px={10}>
                        <Typography align='center' variant='body1' color='primary'>
                            <b>{user ? `Hi ${user.username}` : null}</b>
                        </Typography>
                    </Box>
                    <Box px={5}>
                        <ButtonTemplate type='text' color='primary' onClick={() => navigate('/profile')}>Edit profile</ButtonTemplate>
                  </Box>
                    <Box px={5}>
                        <ButtonTemplate type='text' color='primary' onClick={handleLogout}>Log out</ButtonTemplate>
                    </Box>
                </Box>
            }
          />
        </Grid>
      </Toolbar>
      <Backdrop open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </AppBar>
  )
}