export const GetPayoutReportAdmin = `query MyQuery($Month: String) {
    getPayoutReportAdmin(Month: $Month) {
        UserID
        UserName
        Email
        Videos {
            VideoID
            Slug
            Status
            Deleted
            DeletedAt
            Rents {
                Quickie
                Monthly
                Binge
                Bundle
                Card
                Total
            }
        }
    }
}`