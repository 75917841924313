import React, { useRef, useState } from 'react';
import { ButtonTemplate } from './button-template';
import { Box, Grid, Grow, makeStyles, Paper, Popper } from '@material-ui/core';

export default function DropdownButton (props) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const useStyles = makeStyles((theme) => ({
        paperStyle: {
            backgroundColor: props.backgroundcolor ? props.backgroundcolor : '#fafafa',
            minWidth: anchorRef.current ? anchorRef.current.clientWidth : 'initial',
            border: '1px solid #fff'
        },
        menuItemStyle: {
            color: theme.palette.primary.contrastText
        }
    }))

    const { paperStyle } = useStyles();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box onMouseLeave={handleClose}>
            <ButtonTemplate anchorref={anchorRef} variant='text' onClick={handleOpen} color={props.color}>
                <Grid container alignItems='center'>
                    {props.label}
                </Grid>
            </ButtonTemplate>
            <Popper open={open} anchorEl={anchorRef.current} transition>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                        <Paper className={paperStyle} onClick={handleClose}>
                            {props.dropdowncontent}
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

// Props
// + color
// + label
// + menulist