import { Box, makeStyles, Typography, Grid, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ButtonTemplate } from '../../components/button/button-template';
import { GetTagAdmin, UpdateTagAdmin } from '../../lib/graphql-command/tag-command';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import MessageDialog from '../../components/dialog/message-dialog';

const useStyle = makeStyles(theme => ({
    error: {
        color: theme.palette.primary.main
    },
    File: {
        width: '100%'
    },
    input: {
        display: 'none'
    },
    progress: {
        width: '100%'
    }
}))

const EditTag = ({ TagID }) => {
    const classes = useStyle();
    const [tag, setTag] = useState();
    const [helperText, setHelperText] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [messageDialog, setMessageDialog] = useState();

    
    useEffect(() => {
        API.graphql(graphqlOperation(GetTagAdmin, {TagID}))
        .then(result => setTag(result.data.getTagAdmin))
        .catch(err => setMessageDialog(err.errors[0].message))
    }, [])

    const handleInput = (item, data) => {
        setTag(input => ({
          ...input,
          [item]: data,
        }))
        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
    }

    const handleSubmit = () => {
        if(!tag.Name) {
            setHelperText(helperText => ({
                ...helperText,
                Name: 'Name is required'
            }));
        }
        if(!tag.Slug) {
            setHelperText(helperText => ({
                ...helperText,
                Slug: 'Slug is required'
            }));
        }
        if(tag.Name && tag.Slug) {
            setDisabled(true)
            API.graphql(graphqlOperation(UpdateTagAdmin, tag))
            .then(result => {
                setMessageDialog('Tag updated successfully')
                setDisabled(false)
            })
            .catch(err =>  {
                setMessageDialog(err.errors[0].message);
                setDisabled(false)
            })
        }
    }

    return (
        <Box>
            <Typography variant='h4'>Edit Tag</Typography>
            {tag && <Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Name'
                        defaultValue={tag?.Name}
                        helperText={helperText.Name}
                        onChange={e => {
                            handleInput('Name', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Slug'
                        defaultValue={tag?.Slug}
                        helperText={helperText.Slug}
                        onChange={e => {
                            handleInput('Slug', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Description'
                        defaultValue={tag?.Description}
                        onChange={e => {
                            handleInput('Description', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <Box py={5}>
                        <ButtonTemplate
                            disabled={disabled}
                            borderradius='50px'
                            variant='outlined'
                            color='primary'
                            onClick={handleSubmit}
                        >
                            Save
                        </ButtonTemplate>
                    </Box>
                </Box>
            </Box>}
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
        </Box>
    )
}

export default EditTag;