import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import API, { graphqlOperation } from '@aws-amplify/api';
import { GetPayoutReportAdmin } from '../../lib/graphql-command/payment-command';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ButtonTemplate, primaryButton } from '../../components/button/button-template';

const convertMonth = (value) => {
    return moment(value).format('YYYY-MM-DD');
}

const useStyle = makeStyles(() => ({
    link: {
        textDecoration: 'none'
    }
}))

const defaultRentData = {
    Card: 0,
    Quickie: 0,
    Monthly: 0,
    Binge: 0,
    Bundle: 0
};

const PayoutReport = () => {
    const [month, setMonth] = useState(moment());
    const [disabled, setDisabled] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        setDisabled(true);
        API.graphql(graphqlOperation(GetPayoutReportAdmin, {
            Month: convertMonth(month)
        }))
        .then(result => {
            exportCSV(result.data?.getPayoutReportAdmin);
            console.log(result.data.getPayoutReportAdmin);
            setDisabled(false);
        })
        .catch(err => {
            console.log(err);
            setDisabled(false);
        });
    }, [month]);

    const handleSetMonth = (value) => {
        setMonth(value);
    }

    const processData = (data) => {
        let summary = defaultRentData;
        for (let i in data) {
            data[i].Rents = defaultRentData
            const videos = data[i].Videos;
            for (let j in videos) {
                const videoRent = videos[j].Rents;
                data[i].Rents = {
                    Card: videoRent.Card + data[i].Rents.Card,
                    Quickie: videoRent.Quickie + data[i].Rents.Quickie,
                    Monthly: videoRent.Monthly + data[i].Rents.Monthly,
                    Binge: videoRent.Binge + data[i].Rents.Binge,
                    Bundle: videoRent.Bundle + data[i].Rents.Bundle
                }
                summary = {
                    Card: videoRent.Card + summary.Card,
                    Quickie: videoRent.Quickie + summary.Quickie,
                    Monthly: videoRent.Monthly + summary.Monthly,
                    Binge: videoRent.Binge + summary.Binge,
                    Bundle: videoRent.Bundle + summary.Bundle
                }
            }
        }
        return {
            data,
            summary
        };
    }
    
    const convertCSV = (inputData) => {
        const { data, summary } = inputData;
        let csvContent = `${moment(month).format('YYYY-MM')}-01==${moment(month).month() == moment().month() && moment(month).year() == moment().year() ? moment().format('YYYY-MM-DD') : `${moment(month).endOf('month').format('YYYY-MM-DD')}`}\r\nHere is the Payout Report for ${moment(month).format('MMM YYYY')}\r\nTotal-Payout: $${summary.Card * 2.5 + summary.Quickie * 1.00 + (summary.Monthly + summary.Binge + summary.Bundle) * 0.5}\r\n$${summary.Card * 2.5} = ${summary.Card} à la carte payout @ $2.50 each\r\n$${summary.Quickie} = ${summary.Quickie} rentals by $10 subscribers @ $1.00 each\r\n$${summary.Monthly * 0.5} = ${summary.Monthly} rentals by $30 subscribers @ $0.50 each\r\n$${summary.Binge * 0.5} = ${summary.Binge} rentals by $50 subscribers @ $0.50 each\r\n$${summary.Bundle * 0.5} = ${summary.Bundle} bundle credit rentals @ $0.50 each\r\n\r\n\r\n\r\n\r\n`;

        for (let i in data) {
            const rentSum = data[i].Rents;
            const totalRentSum = rentSum.Card + rentSum.Quickie + rentSum.Monthly + rentSum.Binge + rentSum.Bundle;
            const rentSumTotal = rentSum.Card * 2.5 + rentSum.Quickie * 1.00 + (rentSum.Monthly + rentSum.Binge + rentSum.Bundle) * 0.5;
            let line = `${data[i].UserName} (${data[i].Email})\r\nTotal: ${totalRentSum} ${totalRentSum > 1 ? 'rentals' : 'rental'}, $${rentSumTotal} = (${rentSum.Card} * $2.5 + ${rentSum.Quickie} * $1.00 + ${rentSum.Monthly + rentSum.Binge + rentSum.Bundle} * $0.5)\r\n`;
            const videos = data[i].Videos;
            for (let j in videos) {
                const rents = videos[j].Rents;
                const totalRent = rents.Card + rents.Quickie + rents.Monthly + rents.Binge + rents.Bundle;
                if (videos[j].Deleted === 0) {
                    line += `${videos[j].Slug || videos[j].VideoID}: ${totalRent} ${totalRent > 1 ? 'rentals' : 'rental'}, $${rents.Total} = (${rents.Card} * $2.5 + ${rents.Quickie} * $1.00 + ${rents.Monthly + rents.Binge + rents.Bundle} * $0.5)\r\n`;
                }
            }
            let deletedVideo = false;
            for (let j in videos) {
                if (videos[j].Deleted !== 0) {
                    deletedVideo = true;
                }
            };
            if (!!deletedVideo) line += `*******************Video deleted**********************\r\n`;
            for (let j in videos) {
                const rents = videos[j].Rents;
                const totalRent = rents.Card + rents.Quickie + rents.Monthly + rents.Binge + rents.Bundle;
                if (videos[j].Deleted !== 0) {
                    line += `${videos[j].VideoID}: ${totalRent} ${totalRent > 1 ? 'rentals' : 'rental'}, $${rents.Total} = (${rents.Card} * $2.5 + ${rents.Quickie} * $1.00 + ${rents.Monthly + rents.Binge + rents.Bundle} * $0.5)\r\n`;
                }
            }
            csvContent += line + '\r\n\r\n';
        }
        return csvContent;
    }

    const exportCSV = (csvData) => {
        const newData = processData(csvData);
        const blod = new Blob([convertCSV(newData)], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blod);
        setUrl(url);
    }

    return (
        <Box>
            <Typography variant='h5'>Payout Report</Typography>
            <Box pt={10}>
                <Grid container alignItems='center' spacing={5}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label='Month'
                                autoOk
                                fullWidth
                                size='small'
                                format='MM/yy'
                                variant='inline'
                                inputVariant='outlined'
                                views={['year', 'month']}
                                openTo='month'
                                maxDate={moment()}
                                value={month}
                                InputAdornmentProps={{ position: 'end' }}
                                onChange={date => handleSetMonth(date)}
                                disabled={disabled}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <a
                            href={url}
                            className={useStyle().link}
                            download={`Payout-report ${moment(month).format('YYYY-MM')}.csv`}
                        >
                            <ButtonTemplate
                                {...primaryButton}
                                disabled={disabled}
                            >Download</ButtonTemplate>
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default PayoutReport;