import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, Button, Paper, Tabs, Tab, CircularProgress, InputAdornment } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { ListMarqueeTagAdmin, CreateMarqueeTagAdmin, UpdateMarqueeTagAdmin, DeleteMarqueeTagAdmin } from '../../lib/graphql-command/marquee-command';
import moment from 'moment';
import { Link, navigate } from 'gatsby';
import { Pagination } from '@material-ui/lab';
import { ButtonTemplate } from '../../components/button/button-template';
import { DeleteMarqueeDialog } from '../../components/marquee-tags/delete-marquee-dialog';
import MessageDialog from '../../components/dialog/message-dialog';
import CloseIcon from '@material-ui/icons/Close';

const useStyle = makeStyles(theme => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    trash: {
        color: '#a00'
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    searchBtn: {
        borderRadius: '50px',
        marginLeft: '10px'
    },
    marqueeTitle: {
        width: '40%'
    },
    image: {
        width: '60px',
        height: '60px',
        objectFit: 'cover'
    },
    link: {
        textDecoration: 'none'
    },
    search: {
        flex: 'auto'
    },
    loading: {
        opacity: '0.5',
        pointerEvents: 'none',
        position: 'relative'
    },
    loadingIcon: {
        opacity: '1',
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    textIcon: {
        cursor: 'pointer'
    }
}))

const MarqueeTags = ({ deleted, status }) => {
    const classes = useStyle();
    const [marquees, setMarquees] = useState();
    const [deleteMarquee, setDeleteMarquee] = useState()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({FilterText: ''});
    const [page, setPage] = useState(1)
    const searchRef = useRef();
    const pageSize = 100

    useEffect(() => {
        getListMarquee();
    }, [page, search, deleted, status])

    const getListMarquee = () => {
        setLoading(true);
        API.graphql(graphqlOperation(ListMarqueeTagAdmin, {
            NumberItem: pageSize,
            Page: page,
            Deleted: deleted,
            Status: status,
            FilterText: search.FilterText
        }))
        .then(result => {
            setLoading(false)
            setMarquees(result.data?.getMarqueeTagListAdmin)
        })
        .catch(err => console.log(err))
    }

    const displayDate = marquee => {
        const prefix = marquee.Status === 1 ? `Published` : 'Last Modified'
        return prefix + '\n' + moment(marquee.Status === 1 ? marquee.PublishedAt : (marquee.UpdatedAt || marquee.CreatedAt)).format('YYYY/MM/DD [at] hh:mm a')
    }

    const handleSearch = () => {
        setPage(1)
        setSearch({
            FilterText: searchRef.current.value || ''
        })
    }

    const handleChangeTab = (event, newValue) => {
        setPage(1);
        if(newValue == 1) {
            navigate('/marquee-tags/0/1');
        } else if(newValue == 2) {
            navigate('/marquee-tags/0/0');
        } else if(newValue == 3) {
            navigate('/marquee-tags/1/-1');
        } else {
            navigate('/marquee-tags/0/-1');
        }
    }

    const handleDelete = (e, marquee) => {
        e.preventDefault();
        setDeleteMarquee(marquee)
        setOpenDeleteDialog(true)
  }

    const handleRestore = (e, marquee) => {
        e.preventDefault();
        API.graphql(graphqlOperation(DeleteMarqueeTagAdmin, {MarqueeTagID: marquee.MarqueeTagID, Deleted: 0}))
        .then(result => {
            setMessageDialog('This marquee tag has been restored');
            getListMarquee();
        })
        .catch(err => console.log(err))
    }

    const pageCount = () => {
        return Math.ceil((marquees?.CountAll||0)/pageSize)
    }

    console.log(marquees)

    return (
        <Box>
            <Typography variant='h4'>Marquee Tags</Typography>
            <Box py={10}>
                <Grid container>
                    <Grid item>
                        <Paper>
                            <Tabs
                                value="0"
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                size='small'
                            >
                                <Tab label={"All (" + (marquees?.Total || 0) + ")"} />
                                <Tab label={"Published (" + ((marquees?.Total - marquees?.TotalPending) || 0) + ")"} />
                                <Tab label={"Pending (" + (marquees?.TotalPending || 0) + ")"} />
                                <Tab label={"Trash (" + (marquees?.TotalTrash || 0) + ")"} />
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.search}>
                        <Grid container justify='flex-end' spacing={10}>
                            <Grid item>
                                <Box>
                                    <TextField variant='outlined'
                                        placeholder='Marquee Tag'
                                        size='small'
                                        inputRef={searchRef}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                handleSearch();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.textIcon}
                                                    onClick={e => {
                                                        searchRef.current.value = ''
                                                        handleSearch()
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ButtonTemplate variant='contained'
                                        color='primary'
                                        borderradius='50px'
                                        margin='0 0 0 10px'
                                        onClick={handleSearch}
                                    >Search</ButtonTemplate>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={loading ? classes.loading : ''}>
                {loading && <Box className={classes.loadingIcon}><CircularProgress size={40} /></Box>}
                {marquees?.Items?.length > 0 && <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.marqueeTitle}>Title</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {marquees?.Items?.map(marquee => (
                                <TableRow key={marquee.MarqueeID} className={classes.row}>
                                    <TableCell>
                                        <Link to={`/edit-marquee-tag/${marquee.MarqueeTagID}`} className={classes.link}>{marquee.Name}</Link>
                                        <i>{marquee.Status === 0 ? ' - pending' : marquee.Status === 1 ? ' - published' : ''}</i>
                                        {deleted == 1 ? <Box className={classes.links}>
                                                <Link to='#' onClick={e => handleRestore(e, marquee)}>Restore</Link>
                                                <Link to='#' className={classes.trash} onClick={e => handleDelete(e, marquee)}>Delete Permanently</Link>
                                                <Link target="_blank" to={process.env.GATSBY_USER_SITE + 'tags/' + marquee.Slug +'/'}>View</Link>

                                            </Box>
                                        : <Box className={classes.links}>
                                            <Link to={`/edit-marquee-tag/${marquee.MarqueeTagID}`}>Edit</Link>
                                            <Link to='#' className={classes.trash} onClick={e => handleDelete(e, marquee)}>Trash</Link>
                                            <Link target="_blank" to={process.env.GATSBY_USER_SITE + 'tags/' + marquee.Slug +'/'}>View</Link>
                                        </Box>}
                                    </TableCell>
                                    <TableCell className={classes.date}>{displayDate(marquee)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
            {marquees?.Items?.length <= 0 && <Box>
                No marquee tags found
            </Box>}
            {marquees?.CountAll > 0 && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {marquees?.CountAll} marquee tags
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                                <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
            <DeleteMarqueeDialog {...{open: openDeleteDialog, setOpen: setOpenDeleteDialog, marquee: deleteMarquee, getListMarquee}} />
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
      </Box>
    )
}

export default MarqueeTags;