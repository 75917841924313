import { Box, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ButtonTemplate } from '../../components/button/button-template';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import { Participants } from '../../components/videos/participants';
import { GetFileLink } from '../../lib/amplify-lib';
import { RenameFile } from '../../lib/rename';
import { UpdateVideoArea } from '../../components/videos/update-video-area';
import { EditStills } from '../../components/videos/edit-stills';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { EncodeObject } from '../../lib/encode';
import { GetMarqueeTags, CreateVideoAdmin } from '../../lib/graphql-command/videos-command';
import MessageDialog from '../../components/dialog/message-dialog';
import { Tags } from '../../components/videos/tags';
import { EditAuthor } from '../../components/videos/edit-author';
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { InsertInvitation } from '@material-ui/icons';
import { navigate } from 'gatsby-link';

const useStyle = makeStyles(theme => ({
    input: {
        display: 'none'
    },
    thumbnail: {
        width: "150px",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
    },
    objectCenterSM: {
        [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        },
    },

    textCenterXs: {
        [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        },
    },

    DialogTitleStyle: {
        backgroundColor: theme.palette.primary.main,
    },

    DialogTitleTextStyle: {
        color: theme.palette.primary.contrastText,
        fontSize: "1em",
    },

    DialogContentStyle: {
        paddingTop: 0,
    },

    linkStyle: {
        textDecoration: "none",
        color: theme.palette.primary.main,
    },

    SuccessText: {
        color: theme.palette.success.main,
    },

    primaryColor: {
        color: theme.palette.primary.main,
    },

    VideoUploadStyle: {
        display: "none",
    },
    truncateText: {
        width: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    error: {
        fontStyle: 'italic',
        fontSize: '0.9rem',
        color: theme.palette.primary.main,
        '& li': {
        lineHeight: '1.5rem'
        }
	},
	status: {
		width: '200px'
	}
}))

const bucket = {
    video: process.env.GATSBY_BUCKET_VIDEO,
    image: process.env.GATSBY_BUCKET_IMAGE,
}

const defaultAttributes = {
  VideoTitle: '',
  VideoDescription: '',
  Participants: [],
  Status: 0,
  MarqueeTags: [],
  FirstName: '',
  LastName: '',
  NickName: '',
  Birthday: '',
  Country: null,
  IDImageOne: {},
  IDImageTwo: {},
  VideoReal: {},
  VideoIntro: {},
  VideoFilm: null,
  PublishedAt: moment().format("yyyy-MM-DD HH:MM:ss"),
  UserID: null,
  Tags: [],
  Stills: [],
  VideoDuration: 0
}

const VideoNew = () => {
    const [video, setVideo] = useState(defaultAttributes);
    const classes = useStyle();
    const [participants, setParticipants] = useState([])
    const [marqueeTagOptions, setMarqueeTagOptions] = useState();
    const [disabled, setDisabled] = useState(false);
    const [messageDialog, setMessageDialog] = useState();

    const [loadingProgress, setLoadingProgress] = useState({
        IDImageOne: null,
        IDImageTwo: null,
        VideoIntro: null,
        VideoReal: null,
        Stills: { 0: null, 1: null, 2: null, 3: null, 4: null },
    })

    const [uploadLink, setUploadLink] = useState({
        IDImageOne: "",
        IDImageTwo: "",
        VideoIntro: "",
        VideoReal: "",
        Still_0: "",
        Still_1: "",
        Still_2: "",
        Still_3: "",
        Still_4: "",
    })

    const [uploadFileName, setUploadFileName] = useState({
        IDImageOne: "",
        IDImageTwo: "",
        VideoIntro: "",
        VideoReal: "",
        Still_0: "",
        Still_1: "",
        Still_2: "",
        Still_3: "",
        Still_4: "",
    })

    const [helperText, setHelperText] = useState({
        FirstName: "",
        LastName: "",
        Birthday: "",
        Country: "",
        IDImageOne: "",
        IDImageTwo: "",
        VideoTitle: "",
        VideoDescription: "",
        VideoFilm: "",
        VideoIntro: "",
        VideoReal: "",
        Still_0: "",
        Still_1: "",
        Still_2: "",
        Still_3: "",
        Still_4: "",
        checkbox: "",
    })

    useEffect(() => {
        API.graphql(graphqlOperation(GetMarqueeTags))
        .then(result => setMarqueeTagOptions(result.data.getMarqueeTagList?.Items))
        .catch(err => console.log(err))
    }, [])

    console.log(video)

    const handleInput = (item, data) => {
        setVideo(input => ({
          ...input,
          [item]: data,
        }))
        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
    }

    const handleUpload = (item, file, maxSize, maxSizeText) => {
        if (file) {
          handleInput(item, "")
          setUploadFileName(uploadFileName => ({
            ...uploadFileName,
            [item]: "",
          }))
          setUploadLink(uploadLink => ({
            ...uploadLink,
            [item]: "",
          }))
          setLoadingProgress(loadingProgress => ({
            ...loadingProgress,
            [item]: null,
          }))

          if (file.size > maxSize) {
            setHelperText(helperText => ({
              ...helperText,
              [item]: `Your file is larger than maximum size ${maxSizeText}`,
            }))
            return false
          }

          Storage.configure({
            bucket: file.type.split("/")[0] === "video" ? bucket.video : bucket.image,
          })

          console.log('duration', file.duration)

          Auth.currentCredentials().then(data => {
            const key = (file.type.split("/")[0] === "video"
                ? `${data.identityId}/uploads/`
                : `public/images/${data.identityId}/`) + RenameFile(file.name)
            Storage.put(key, file, {
              contentType: file.type,
              customPrefix: {
                public: "",
              },
              progressCallback(progress) {
                setLoadingProgress(loadingProgress => ({
                  ...loadingProgress,
                  [item]: (100 * progress.loaded) / progress.total,
                }))
              },
            })
              .then(data => {
                GetFileLink(data.key).then(data =>
                  setUploadLink(uploadLink => ({
                    ...uploadLink,
                    [item]: data,
                  }))
                )
                handleInput(item, {
                  region: "us-west-2",
                  bucket:
                    file.type.split("/")[0] === "video"
                      ? bucket.video
                      : bucket.image,
                  key: data.key,
                })
              })
              .catch(err => console.log(err))

            setUploadFileName(uploadFileName => ({
              ...uploadFileName,
              [item]: file.name,
            }))
          })
        }
    }

    const StatusOptions = [
        {
            key: 'pending',
            text: 'Pending',
            value: 0
        }, {
            key: 'published',
            text: 'Published',
            value: 1
        }, {
            key: 'draft',
            text: 'Draft',
            value: 2
        }
    ]

    const mandatoryFields = [
        { label: "Video's title", key: "VideoTitle" },
        { label: "Video's description", key: "VideoDescription" },
        { label: "Video's date", key: "VideoFilm" },
        { label: "Intro Video", key: "VideoIntro" },
        { label: "Your Video", key: "VideoReal" }
    ]
    const checkHelperText = item => {
        let error = false
        if (!video[item.key]) {
          setHelperText(helperText => ({
            ...helperText,
            [item.key]: `${item.label} is required`,
          }))
          error = true
        }

        if (error) {
          return false
        }

        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
        return true
    }

    const processStills = data => {
        const newData = data
        const stillData = []
        for (let i = 0; i < 5; i++) {
          if (newData[`Still_${i}`]) {
            stillData.push(newData[`Still_${i}`])
          }
        }
        newData.Stills = stillData
        return newData
    }

    const handleSubmit = () => {
        setDisabled(true)
        let isValid = true
        for (let i in mandatoryFields) {
            if (!checkHelperText(mandatoryFields[i])) {
                isValid = false
            }
        }
        if(!video.FirstName) {
            setHelperText(helperText => ({
                ...helperText,
                Participants: "Video's participants is required",
            }))
            setDisabled(false)
            return;
        }
        if (isValid) {
            API.graphql(graphqlOperation(CreateVideoAdmin, EncodeObject(processStills(video))))
            .then(() => {
                setDisabled(false)
                setMessageDialog('Create successfully')
                setTimeout(() => {
                    navigate('/videos')
                }, 1000)
            })
            .catch(err => {
                console.log(err)
            })
        } else {
            console.log('error')
            setDisabled(false)
        }
    }

    const handleSelectMarqueeTags = values => {
        setVideo(oldVideo => ({
            ...oldVideo, 'MarqueeTags': values.map(value => value.MarqueeTagID)
        }))
    }

    return (
        <Box p={10} className={classes.root}>
            {video && <Box>
                <Typography variant="h5">
                    New Video
                </Typography>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Video title'
                        defaultValue={video?.VideoTitle}
                        helperText={helperText.VideoTitle}
                        onChange={e => {
                            handleInput('VideoTitle', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5} className={classes.status}>
                    <Autocomplete
                        options={StatusOptions}
                        size="small"
                        getOptionLabel={option => option.text}
                        clearOnBlur
                        closeIcon={false}
                        style={{width: 200}}
                        value={StatusOptions[video.Status]}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Status"
                                helperText={helperText.Status}
                                className={classes.textfieldStyle}
                            />
                        )}
                        onChange={(event, value) => value && handleInput('Status', value.value)}
                    />
                </Box>
                <Box>
                    <Typography color="primary" variant="h6">
                        Participants
                    </Typography>
                    <Box>
                        <Participants {...{participants, setParticipants, video, setVideo}}/>
                        {!video.FirstName && <span style={{color: 'red', fontStyle: 'italic'}}>{helperText.Participants}</span>}
                    </Box>
                </Box>
                <Box>
                    <Grid container>
                        <Grid item md={6}>
                            <UpdateVideoArea {...{video, handleUpload, uploadFileName, uploadLink, helperText, loadingProgress, handleInput}} />
                        </Grid>
                        <Grid item md={6}>
                            <EditStills {...{video, handleUpload, uploadFileName, uploadLink, loadingProgress, helperText}} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={10} justify='space-between'>
                        <Grid item md={6}>
                          <Box py={10}>
                              <Box py={5}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DatePicker
                                          autoOk
                                          variant="inline"
                                          inputVariant="outlined"
                                          format="MM/dd/yyyy"
                                          size="small"
                                          label='Film Date'
                                          value={video.VideoFilm}
                                          helperText={helperText.VideoFilm}
                                          error={helperText.VideoFilm ? true : false}
                                          maxDate={Date.now()}
                                          InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton>
                                                    <InsertInvitation />
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                          }}
                                          onChange={date => {
                                              if (date) {
                                                  if (date.toString() === "Invalid Date") {
                                                      setVideo(input => ({
                                                          ...input,
                                                          VideoFilm: null,
                                                      }))
                                                      setHelperText(helperText => ({
                                                      ...helperText,
                                                      VideoFilm:
                                                          "Please enter date in the requested format - MM/DD/YYYY",
                                                      }))
                                                  } else {
                                                      handleInput('VideoFilm', moment(date).format("YYYY-MM-DD"))
                                                  }
                                              }
                                          }}
                                      />
                                  </MuiPickersUtilsProvider>
                              </Box>

                              <Box py={5}>
                                  <TextFieldTemplate
                                      multiline
                                      rows={4}
                                      size='small'
                                      label='Description'
                                      helperText={helperText.VideoDescription}
                                      defaultValue={video.VideoDescription}
                                      onChange={e => handleInput('VideoDescription', e.target.value)}
                                      />
                              </Box>
                              <Box py={5}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DateTimePicker
                                          inputVariant="outlined"
                                          variant="inline"
                                          label="Publish on"
                                          size="small"
                                          format="MMM dd, yyyy HH:MM"
                                          ampm={false}
                                          value={video.PublishedAt}
                                          InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton>
                                                    <AlarmIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                          }}
                                          onChange={(datetime) => {
                                              handleInput('PublishedAt', moment(datetime).format("yyyy-MM-DD HH:MM:ss"))
                                          }}
                                      />
                                  </MuiPickersUtilsProvider>
                              </Box>

                              <EditAuthor {...{video, handleInput}} />
                          </Box>
                        </Grid>
                        <Grid item md={4}>

                            <Tags {...{video, setVideo}} />
                            {marqueeTagOptions && <Box>
                                <Autocomplete
                                    multiple
                                    options={marqueeTagOptions}
                                    size="small"
                                    getOptionLabel={option => option.Name}
                                    disableCloseOnSelect
                                    defaultValue={marqueeTagOptions.filter(tag => video.MarqueeTags.includes(tag.MarqueeTagID))}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Marquee tags"
                                            className={classes.textfieldStyle}
                                        />
                                    )}
                                    onChange={(event, values) => handleSelectMarqueeTags(values)}
                                />
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
                <ButtonTemplate
                    disabled={disabled}
                    borderradius='50px'
                    variant='outlined'
                    color='primary'
                    onClick={handleSubmit}
                >
                    Save
                </ButtonTemplate>

            </Box>}
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
        </Box>
    )
}

export default VideoNew;