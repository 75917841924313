import React, { useContext, useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { ButtonTemplate, primaryButton } from '../../components/button/button-template';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import { grey } from '@material-ui/core/colors';
import { isRequired, validateData } from '../../config/form-config/config';
import { AuthContext } from '../../context/auth-context';
import { navigate } from 'gatsby';
import { Alert } from '@material-ui/lab';
import Amplify from 'aws-amplify';

const useStyle = makeStyles(theme => ({
  background: {
    height: '100vh'
  },
  loginArea: {
    maxWidth: '400px',
    margin: '0 15px',
    backgroundColor: grey[100],
    borderRadius: '0 0 5px 5px'
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '5px 5px 0 0',
    padding: '10px'
  },
  inputArea: {
    padding: '30px 30px 10px 30px'
  },
  input: {
    padding: '10px 0'
  },
  buttonArea: {
    padding: '0 30px 20px 30px'
  }
}));

const LoginForm = [
  { key: 'username', label: 'Email/Username', validateFuncs: [isRequired('Email/Username')] },
  { key: 'password', label: 'Password', type: 'password', validateFuncs: [isRequired('Password')] }
];

const defaultUser = { username: '', password: '' };

const Login = () => {
  const [user, setUser] = useState(defaultUser);
  const [errors, setErrors] = useState(defaultUser);
  const [alert, setAlert] = useState({severity: '', message: ''});
  const [disabled, setDisabled] = useState(false);
  const { setLoggedInUser, setIsLoggedIn } = useContext(AuthContext);
  const { background, buttonArea, inputArea, input, loginArea, title } = useStyle();

  const handleSubmit = () => {
    setErrors({});
    setDisabled(true);
    const error = validateData(user, LoginForm);
    console.log(error)
    if (error.username || error.password) {
      setErrors(error);
      setDisabled(false);
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `https://makelovenotporn.tv/api/login`);

      const data = new FormData();
      data.append("username", user.username.trim());
      data.append("password", user.password.trim());

      xhr.onload = () => {
          if (xhr.status !== 200 && xhr.status !== 401) {
              setDisabled(false);
              setAlert({severity: 'error', message: "An error occurred. Please try again."});
              return;
          }

          const data = JSON.parse(xhr.responseText);
          if (data.message) {
              setDisabled(false);
              setAlert({severity: 'error', message: data.message});
              return;
          }
          setLoggedInUser({username: data.username});
          setIsLoggedIn(true);
          navigate('/');

          document.cookie = "username=" + data.username + ";path=/";
          document.cookie = "apiurl=" + data.apiUrl + ";path=/";
          document.cookie = "apiregion=" + data.apiRegion + ";path=/";
          document.cookie = "apikey=" + data.apiKey + ";path=/";
          document.cookie = "mlnp_session=" + data.session + ";path=/";

          let myAppConfig = {
            aws_appsync_graphqlEndpoint: data.apiUrl,
            aws_appsync_region: data.apiRegion,
            aws_appsync_authenticationType: "API_KEY",
            aws_appsync_apiKey: data.apiKey,
          }
          
          Amplify.configure(myAppConfig)
      };

      xhr.send(data);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <Grid container direction='row' alignItems='center' justify='center' className={background}>
      <Grid item xs={12} md={4} className={loginArea}>
        <Box className={title}>
          <Typography align='center'>Login</Typography>
        </Box>
        <Box pt={10} px={15}>
          {alert && alert.message !== '' ?
            <Alert severity={alert.severity}>{alert.message}</Alert>
          : null }
        </Box>
        <Box className={inputArea}>
          {LoginForm.map(item => (
            <Box className={input} key={item.key}>
              <TextFieldTemplate
                label={item.label}
                helperText={errors[item.key]}
                type={item.type}
                onChange={e => setUser({...user, [item.key]: e.target.value})}
                onKeyDown={handleKeyDown}
              />
            </Box>
          ))}
        </Box>
        <Box className={buttonArea}>
          <ButtonTemplate
            {...primaryButton}
            fullWidth
            onClick={handleSubmit}
            disabled={disabled}
          >Login</ButtonTemplate>
        </Box>
        <Box py={10}>
          <Typography variant='body2' align='center'>Forgot password?</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Login;