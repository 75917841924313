import { Box, makeStyles, Typography, Grid, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ButtonTemplate } from '../../components/button/button-template';
import { GetMarqueeTagAdmin, UpdateMarqueeTagAdmin } from '../../lib/graphql-command/marquee-command';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import MessageDialog from '../../components/dialog/message-dialog';

const useStyle = makeStyles(theme => ({
    error: {
        color: theme.palette.primary.main
    },
    File: {
        width: '100%'
    },
    input: {
        display: 'none'
    },
    progress: {
        width: '100%'
    }
}))

const EditMarqueeTag = ({ MarqueeTagID }) => {
    const classes = useStyle();
    const [marquee, setMarquee] = useState();
    const [helperText, setHelperText] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [messageDialog, setMessageDialog] = useState();

    const StatusOptions = [
        {
            key: 'published',
            text: 'Published',
            value: 1
        }, {
            key: 'pending',
            text: 'Pending',
            value: 0
        }
    ]

    
    useEffect(() => {
        API.graphql(graphqlOperation(GetMarqueeTagAdmin, {MarqueeTagID}))
        .then(result => setMarquee(result.data.getMarqueeTagAdmin))
        .catch(err => setMessageDialog(err.errors[0].message))
    }, [])

    const handleInput = (item, data) => {
        setMarquee(input => ({
          ...input,
          [item]: data,
        }))
        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
    }

    const handleSubmit = () => {
        if(!marquee.Name) {
            setHelperText(helperText => ({
                ...helperText,
                Name: 'Name is required'
            }));
        }
        if(!marquee.Slug) {
            setHelperText(helperText => ({
                ...helperText,
                Slug: 'Slug is required'
            }));
        }
        if(marquee.Name && marquee.Slug) {
            setDisabled(true)
            API.graphql(graphqlOperation(UpdateMarqueeTagAdmin, marquee))
            .then(result => {
                setMessageDialog('Marquee tag updated successfully')
                setDisabled(false)
            })
            .catch(err =>  {
                setMessageDialog(err.errors[0].message);
                setDisabled(false)
            })
        }
    }

    console.log(marquee)

    return (
        <Box>
            <Typography variant='h4'>Edit Marquee</Typography>
            {marquee && <Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Name'
                        defaultValue={marquee?.Name}
                        helperText={helperText.Name}
                        onChange={e => {
                            handleInput('Name', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Slug'
                        defaultValue={marquee?.Slug}
                        helperText={helperText.Slug}
                        onChange={e => {
                            handleInput('Slug', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <FormControl variant="outlined" size='small'>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={marquee.Status}
                            onChange={e => handleInput('Status', e.target.value)}
                        >
                            {StatusOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box py={5}>
                    <Box py={5}>
                        <ButtonTemplate
                            disabled={disabled}
                            borderradius='50px'
                            variant='outlined'
                            color='primary'
                            onClick={handleSubmit}
                        >
                            Save
                        </ButtonTemplate>
                    </Box>
                </Box>
            </Box>}
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
        </Box>
    )
}

export default EditMarqueeTag;