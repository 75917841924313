import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { AuthContext } from '../context/auth-context';
import Layout from '../components/layout/layout';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const { isLoggedIn } = useContext(AuthContext);
    
    if (!isLoggedIn && location.pathname !== '/login') {
        navigate('/login');
        return null;
    }

    return (
        <Layout>
            <Component {...rest} />
        </Layout>
    )
}

export default PrivateRoute;