import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, Button, Paper, Tabs, Tab, CircularProgress, InputAdornment } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { ListTagAdmin, DeleteTagAdmin } from '../../lib/graphql-command/tag-command';
import { Link } from 'gatsby';
import { Pagination } from '@material-ui/lab';
import { ButtonTemplate } from '../../components/button/button-template';
import { DeleteTagDialog } from '../../components/tags/delete-tag-dialog';
import MessageDialog from '../../components/dialog/message-dialog';
import CloseIcon from '@material-ui/icons/Close';

const useStyle = makeStyles(theme => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    trash: {
        color: '#a00'
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    searchBtn: {
        borderRadius: '50px',
        marginLeft: '10px'
    },
    tagTitle: {
        width: '40%'
    },
    image: {
        width: '60px',
        height: '60px',
        objectFit: 'cover'
    },
    link: {
        textDecoration: 'none'
    },
    search: {
        flex: 'auto'
    },
    loading: {
        opacity: '0.5',
        pointerEvents: 'none',
        position: 'relative'
    },
    loadingIcon: {
        opacity: '1',
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    textIcon: {
        cursor: 'pointer'
    }
}))

const Tags = () => {
    const classes = useStyle();
    const [tags, setTags] = useState();
    const [deleteTag, setDeleteTag] = useState()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({FilterText: ''});
    const [page, setPage] = useState(1)
    const searchRef = useRef();
    const pageSize = 100

    useEffect(() => {
        getListTag();
    }, [page, search])

    const getListTag = () => {
        setLoading(true);
        API.graphql(graphqlOperation(ListTagAdmin, {
            NumberItem: pageSize,
            Page: page,
            FilterText: search.FilterText
        }))
        .then(result => {
            setLoading(false)
            setTags(result.data?.getTagListAdmin)
        })
        .catch(err => console.log(err))
    }

    const handleSearch = () => {
        setPage(1)
        setSearch({
            FilterText: searchRef.current.value || ''
        })
    }

    const handleDelete = (e, tag) => {
        e.preventDefault();
        setDeleteTag(tag)
        setOpenDeleteDialog(true)
  }

    const pageCount = () => {
        return Math.ceil((tags?.CountAll||0)/pageSize)
    }

    console.log(tags)

    return (
        <Box>
            <Typography variant='h4'>Tags</Typography>
            <Box py={10}>
                <Grid container>
                    <Grid item className={classes.search}>
                        <Grid container justify='flex-end' spacing={10}>
                            <Grid item>
                                <Box>
                                    <TextField variant='outlined'
                                        placeholder='Tag'
                                        size='small'
                                        inputRef={searchRef}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                handleSearch();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.textIcon}
                                                    onClick={e => {
                                                        searchRef.current.value = ''
                                                        handleSearch()
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ButtonTemplate variant='contained'
                                        color='primary'
                                        borderradius='50px'
                                        margin='0 0 0 10px'
                                        onClick={handleSearch}
                                    >Search Tags</ButtonTemplate>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={loading ? classes.loading : ''}>
                {loading && <Box className={classes.loadingIcon}><CircularProgress size={40} /></Box>}
                {tags?.Items?.length > 0 && <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tagTitle}>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Slug</TableCell>
                                <TableCell>Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tags?.Items?.map(tag => (
                                <TableRow key={tag.TagID} className={classes.row}>
                                    <TableCell>
                                        <Link to={`/edit-tag/${tag.TagID}`} className={classes.link}>{tag.Name}</Link>
                                        <Box className={classes.links}>
                                            <Link to={`/edit-tag/${tag.TagID}`}>Edit</Link>
                                            <Link to='#' className={classes.trash} onClick={e => handleDelete(e, tag)}>Delete</Link>
                                            <Link target="_blank" to={process.env.GATSBY_USER_SITE + 'video-tag/' + tag.Slug +'/'}>View</Link>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{tag.Description}</TableCell>
                                    <TableCell>{tag.Slug}</TableCell>
                                    <TableCell>{tag.Count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
            {tags?.Items?.length <= 0 && <Box>
                No tags found
            </Box>}
            {tags?.CountAll > 0 && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {tags?.CountAll} tags
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                                <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
            <DeleteTagDialog {...{open: openDeleteDialog, setOpen: setOpenDeleteDialog, tag: deleteTag, getListTag}} />
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
      </Box>
    )
}

export default Tags;