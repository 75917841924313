import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { GetCommentAdmin, UpdateCommentAdmin } from '../../lib/graphql-command/comment-command';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import { ButtonTemplate, primaryButton } from '../../components/button/button-template';
import MessageDialog from '../../components/dialog/message-dialog';

const defaultComment = {
    UserName: '',
    Email: '',
    Content: '',
    Status: 0,
    Deleted: 0
}

const EditComment = ({ CommentID }) => {
    const [comment, setComment] = useState(defaultComment);
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        API.graphql(graphqlOperation(GetCommentAdmin, { CommentID: CommentID }))
        .then(result => setComment(result.data?.getCommentAdmin))
        .catch(err => console.log(err));
    }, []);

    const handleUpdate = () => {
        setDisabled(true);

        API.graphql(graphqlOperation(UpdateCommentAdmin, {
            CommentID: CommentID,
            Content: comment.Content,
            Deleted: comment.Deleted,
            Status: comment.Status
        }))
        .then(result => {
            console.log(result);
            setDisabled(false);
            setMessage('Update successfully');
        })
        .catch(err => {
            console.log(err);
            setDisabled(false);
        });
    };



    return (
        <Box px={10}>
            <MessageDialog {...{ message, setMessage }} />
            <Typography variant="h5">Edit Comment</Typography>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Paper>
                            <Box p={10}>
                                <Typography variant="body1">Author</Typography>
                                <Box py={10}>
                                    <TextFieldTemplate
                                        label='Name'
                                        value={comment.UserName}
                                        disabled
                                        />
                                </Box>
                                <Box py={10}>
                                    <TextFieldTemplate
                                        label='Email'
                                        disabled
                                        value={comment.Email}
                                        />
                                </Box>
                                <Box py={10}>
                                    <TextFieldTemplate
                                        label='Comment'
                                        value={comment.Content}
                                        multiline
                                        rows={7}
                                        onChange={e => {
                                            const value = e.target.value
                                            setComment(comment => ({
                                                ...comment,
                                                Content: value
                                            }))
                                        }}
                                    />
                                </Box>

                                <Box py={10}>
                                    <ButtonTemplate
                                        {...primaryButton}
                                        onClick={handleUpdate}
                                        disabled={disabled}
                                    >Save</ButtonTemplate>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default EditComment;