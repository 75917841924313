import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Appbar } from './appbar';
import { Sidebar } from './sidebar';

const drawerWidth = 220;

const useStyle = makeStyles({
    content: {
        marginLeft: open => open ? drawerWidth : 0
    }
})

export default function Layout({ children }) {
    const [open, setOpen] = useState(true);
    const { content } = useStyle(open);

    return (
        <Box>
            <Appbar {...{ open, setOpen, drawerWidth }} />
            <Sidebar {...{ open, setOpen, drawerWidth }} />
            <Box p={10} className={content}>
                {children}
            </Box>
        </Box>
    )
}