import React from 'react';
import { Router } from '@reach/router';
import AuthProvider from '../context/auth-context';
import PrivateRoute from '../privaterule/private-route';
import UnPrivateRoute from '../privaterule/unprivate-route';
import '../styles/style.scss';
import Videos from './app/videos';
import Users from './app/users';
import SocialPost from './app/social-post';
import { ThemeProvider } from '@material-ui/core';
import theme from '../theme/theme';
import Dashboard from './app/dashboard';
import RentalHistories from './app/rental-histories';
import Login from './app/login';
import EditUser from './app/edit-user';
import EditVideo from './app/edit-video';
import VideoNew from './app/video-new';
import SubscriptionHistory from './app/subscription-history';
import UserConfirmation from './app/user-confirmation';
import MarqueeTags from './app/marquee-tags';
import NewUser from './app/new-user';
import Comments from './app/comments';
import EditComment from './app/edit-comment';
import EditSocial from './app/edit-social';
import NewSocial from './app/new-social';
import NewMarqueeTag from './app/new-marquee-tag';
import EditMarqueeTag from './app/edit-marquee-tag';
import Tags from './app/tags';
import NewTag from './app/new-tag';
import EditTag from './app/edit-tag';
import PayoutReport from './app/payout-report';
import Profile from './app/profile';

const App = () => (
    <AuthProvider>
        <ThemeProvider theme={theme}>
            <Router>
                <UnPrivateRoute path='/login' component={Login} />
                <PrivateRoute path='/' component={Dashboard} />
                <PrivateRoute path='/profile' component={Profile} />
                <PrivateRoute path='/videos' component={Videos} />
                <PrivateRoute path='/new-video' component={VideoNew} />
                <PrivateRoute path='/videos/:videoCode/edit' component={EditVideo} />
                <PrivateRoute path='/users' component={Users} />
                <PrivateRoute path='/users/:UserID' component={EditUser} />
                <PrivateRoute path='/new-user' component={NewUser} />
                <PrivateRoute path='/social-post' component={SocialPost} />
                <PrivateRoute path='/new-social' component={NewSocial} />
                <PrivateRoute path='/user-confirmation' component={UserConfirmation} />
                <PrivateRoute path='/social-post/:SocialPostID' component={EditSocial} />
                <PrivateRoute path='/rental-histories/:UserID' component={RentalHistories} />
                <PrivateRoute path='/subscription-history' component={SubscriptionHistory} />
                <PrivateRoute path='/payout-report' component={PayoutReport} />
                <PrivateRoute path='/comments' component={Comments} />
                <PrivateRoute path='/edit-comment/:CommentID' component={EditComment} />
                <PrivateRoute path='/marquee-tags/:deleted/:status' component={MarqueeTags} />
                <PrivateRoute path='/new-marquee-tag' component={NewMarqueeTag} />
                <PrivateRoute path='/edit-marquee-tag/:MarqueeTagID' component={EditMarqueeTag} />
                <PrivateRoute path='/tags' component={Tags} />
                <PrivateRoute path='/new-tag' component={NewTag} />
                <PrivateRoute path='/edit-tag/:TagID' component={EditTag} />
            </Router>
        </ThemeProvider>
    </AuthProvider>
)

export default App;
