import React, { useContext } from 'react';
import { AuthContext } from '../../context/auth-context';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Drawer, IconButton, makeStyles, MenuItem, MenuList, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Constants from '../../constants/constants';
import { Link } from 'gatsby';

const useStyle = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth => `${drawerWidth}px`,
    backgroundColor: theme.palette.thirdly.dark
  },
  closeIcon: {
    float: 'right',
    color: '#fff'
  },
  menu: {
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.thirdly.light
  },
  linkArea: {
    '&:hover': {
      backgroundColor: theme.palette.thirdly.light,
      '& *': {
        color: theme.palette.thirdly.dark
      }
    }
  },
  linkFocus: {
    textDecoration: 'none',
    color: theme.palette.thirdly.dark
  },
  linkAreaFocus: {
    '&:hover': {
      backgroundColor: theme.palette.thirdly.light,
    }
  }
}));

export const Sidebar = ({ open, setOpen, drawerWidth }) => {
  const { expanded, setExpanded } = useContext(AuthContext);
  const { drawerPaper, closeIcon, link, linkArea, menu } = useStyle(drawerWidth);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const SidebarList = (data) => (
    <div>
      {data.map(item => item.dropdownList ?
        <Accordion
          expanded={item.label === expanded}
          onChange={handleChange(item.label)}
          key={item.label}
          className={drawerPaper}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon className={link} />} className={linkArea}>
            <Box px={10} className={link}>
              <Typography>{item.label}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <MenuList className={menu}>
              {item.dropdownList.map(item => (
                <Link
                  key={item.label}
                  to={item.link}
                  className={link}
                >
                  <MenuItem className={linkArea} key={item.label}>
                    <Typography variant='body2'>{item.label}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          </AccordionDetails>
        </Accordion>
      :
        <Link
          key={item.label}
          to={`${item.link}`}
          className={link}
        >
          <Box py={5} px={10} className={linkArea}>
            <Typography variant='body1'>{item.label}</Typography>
          </Box>
        </Link>
      )}
    </div>
  )

  return (
    <Drawer
      open={open}
      variant='persistent'
      onClose={() => setOpen(false)}
      classes={{
        paper: drawerPaper
      }}
    >
      <Box>
        <IconButton
          onClick={() => setOpen(false)}
          className={closeIcon}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box>
        {SidebarList(Constants.Appbar)}
      </Box>
    </Drawer>
  )
}