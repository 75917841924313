import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import { AuthContext } from '../context/auth-context';

const UnPrivateRoute = ({ component: Component, location, ...rest }) => {
    const { isLoggedIn } = useContext(AuthContext);
    
    if (isLoggedIn) {
        navigate('/');
        return null;
    }

    return <Component {...rest} />
}

export default UnPrivateRoute;