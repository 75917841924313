import React, { useEffect, useRef, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Typography, Grid, TextField, CircularProgress, InputAdornment, Paper, Tab, Tabs } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { Link, navigate } from 'gatsby';
import { Pagination } from '@material-ui/lab';
import { ButtonTemplate, primaryButton } from '../../components/button/button-template';
import MessageDialog from '../../components/dialog/message-dialog';
import { ListUserAdmin } from '../../lib/graphql-command/user-command';
import { DeleteUserDialog } from '../../components/users/delete-user-dialog';
import { getImage } from '../../lib/utils';
import noimage from '../../images/MLNP_Heart_Symbol.png'
import CloseIcon from '@material-ui/icons/Close';

const useStyle = makeStyles(theme => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    date: {
        whiteSpace: 'break-spaces'
    },
    links: {
        display: 'none',
        minHeight: '5px',
        '& a': {
            marginRight: '5px',
            textDecoration: 'none',
            fontSize: '0.9rem'
        }
    },
    trash: {
        color: '#a00'
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    searchBtn: {
        borderRadius: '50px',
        marginLeft: '10px'
	},
	videoTitle: {
		width: '40%'
	},
	image: {
		width: '60px',
		height: '60px',
		objectFit: 'cover'
	},
	link: {
		textDecoration: 'none'
	},
	avatar: {
		width: '60px',
		height: '60px',
		objectFit: 'cover'
	},
	username: {
		verticalAlign: 'top'
    },
    loading: {
        opacity: '0.5',
        pointerEvents: 'none',
        position: 'relative'
    },
    loadingIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    textIcon: {
        cursor: 'pointer'
    },
    search: {
        flex: 'auto'
    }
}))

const Users = () => {
    const classes = useStyle();
    const [users, setUsers] = useState();
    const [deleteUser, setDeleteUser] = useState()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [messageDialog, setMessageDialog] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState({FilterText: '', FilterDate: null});
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState(0);
    const searchRef = useRef();
    const pageSize = 20
    const filters = [
        {
            key: 'all',
            label: 'Total',
            value: 0
        },{
            key: 'admin',
            label: 'Admin',
            value: 1
        }, {
            key: 'star',
            label: 'Star',
            value: 2
        }, {
            key: 'member',
            label: 'Member',
            value: 3
        }, {
            key: 'norole',
            label: 'NoRole',
            value: 4
        }
    ]

    useEffect(() => {
        getListUser();
    }, [page, search, filter])

    const getListUser = () => {
        setLoading(true)
        API.graphql(graphqlOperation(ListUserAdmin, {
            Limit: pageSize,
            Page: page,
            Search: search.FilterText,
            Filter: filters[filter].key
        }))
        .then(result => {
            setUsers(result.data.listUserAdmin)
            setLoading(false)
        })
        .catch(err => console.log(err))
    }

    const handleSearch = () => {
        setPage(1)
        setSearch({
			FilterText: searchRef.current.value || ''
		})
	}

	const handleDelete = (e, user) => {
		e.preventDefault();
		setDeleteUser(user)
		setOpenDeleteDialog(true)
	}

    const pageCount = () => {
        return Math.ceil((total()||0)/pageSize)
    }

    const displayRole = (user) => {
        return user.Role === 1 ? 'Administrator' : user.Post > 0 ? 'MLNPStar' : 'MLNPMember'
    }

    const total = () => {
        return users?.Count[filters[filter]?.label]
    }

    const handleChangeTab = (e, newValue) => {
        setPage(1)
        setFilter(newValue)
    }

    console.log(users)

    return (
        <Box>
            <Grid container>
                <Typography variant='h4'>Users</Typography>
                <ButtonTemplate
                    borderradius='50px'
                    variant='outlined'
                    color='primary'
                    size='small'
                    margin='0 0 5px 10px'
                    onClick={() => navigate('/new-user')}
                >Add New</ButtonTemplate>
            </Grid>
			<Box py={10}>
                <Grid container spacing={6}>
                    <Grid item>
                        <Paper>
                            <Tabs
                                value={filter}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                size='small'
                            >
                                <Tab label={`All (${users?.Count?.Total || 0})`} />
                                <Tab label={`Administrator (${users?.Count?.Admin || 0})`} />
                                <Tab label={`MLNPStar (${users?.Count?.Star || 0})`} />
                                <Tab label={`MLNPMember (${users?.Count?.Member || 0})`} />
                                <Tab label={`No role (${users?.Count?.NoRole || 0})`} />
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.search}>
                        <Grid container justify='flex-end' spacing={10}>
                            <Grid item>
                                <Box>
                                    <TextField variant='outlined'
                                        size='small'
                                        inputRef={searchRef}
                                        onKeyDown={e => {
                                            if (e.keyCode === 13) {
                                                handleSearch();
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={classes.textIcon}
                                                    onClick={e => {
                                                        searchRef.current.value = ''
                                                        handleSearch()
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ButtonTemplate variant='contained'
                                        color='primary'
                                        borderradius='50px'
                                        margin='0 0 0 10px'
                                        onClick={handleSearch}
                                    >Search Users</ButtonTemplate>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={loading ? classes.loading : ''}>
                {loading && <Box className={classes.loadingIcon}><CircularProgress size={40} /></Box>}
                {users?.Items?.length > 0 && <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Videos</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.Items?.map(user => (
                                <TableRow key={user.UserID} className={classes.row}>
                                    <TableCell className={classes.username}>
                                        <Grid container spacing={5}>
                                            <Grid item>
                                                <img src={user.Avatar?.key ? getImage(user.Avatar) : noimage} alt={user.UserName} className={classes.avatar}/>
                                            </Grid>
                                            <Grid item>
                                            <Link to={`/users/${user.UserID}`} className={classes.link}>{user.UserName}</Link>
                                                <Box className={classes.links}>
                                                    <Link to={`/users/${user.UserID}`}>Edit</Link>
                                                    {user.Deleted === 0 && <Link to='#' className={classes.trash} onClick={e => handleDelete(e, user)}>Delete</Link>}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        {user.DisplayName}
                                    </TableCell>
                                    <TableCell>
                                        {user.Email}
                                    </TableCell>
                                    <TableCell>{displayRole(user)}</TableCell>
                                    <TableCell>{user.Post}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
      {users?.Items?.length <= 0 && <Box>
        No users found
      </Box>}
            {total() > 0 && <Box py={10}>
                <Grid container>
                    <Grid item>
                        {total()} users
                    </Grid>
                    <Grid item>
                        {pageCount() > 1 && <Box pb={10}>
                                <Pagination count={pageCount()} onChange={(e, value) => setPage(value)} page={page} color="primary"/>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
            <DeleteUserDialog {...{open: openDeleteDialog, setOpen: setOpenDeleteDialog, user: deleteUser, getListUser}} />
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
      </Box>
    )
}

export default Users;