export const CreateMarqueeTagAdmin = `mutation MyMutation($Name: String, $Slug: String, $Status: Int, $MarqueeGroup: [Int]) {
    createMarqueeTagAdmin(input: {Name: $Name, Slug: $Slug, Status: $Status, MarqueeGroup: $MarqueeGroup}) {
        Status
	    Message
    }
}`

export const UpdateMarqueeTagAdmin = `mutation MyMutation($MarqueeTagID: Int, $Name: String, $Slug: String, $Status: Int, $MarqueeGroup: [Int]) {
    updateMarqueeTagAdmin(input: {MarqueeTagID: $MarqueeTagID, Name: $Name, Slug: $Slug, Status: $Status, MarqueeGroup: $MarqueeGroup}) {
        Status
	    Message
    }
}`

export const ListMarqueeTagAdmin = `query MyQuery($NumberItem: Int, $Page: Int, $Status: Int, $Deleted: Int, $FilterText: String) {
    getMarqueeTagListAdmin(input: {NumberItem: $NumberItem, Page: $Page, Status: $Status, Deleted: $Deleted, FilterText: $FilterText}) {
        Total
        TotalPending
        TotalTrash
        CountAll
        NumberItem
        Page
        Status
        Deleted
        FilterText
        Items {
            MarqueeTagID
            Name
            Slug
            Status
            Deleted
            CreatedAt
            UpdatedAt
            PublishedAt
        }
    }
}`

export const DeleteMarqueeTagAdmin = `mutation MyMutation($MarqueeTagID: Int!, $Deleted: Int!) {
    deleteMarqueeTagAdmin(MarqueeTagID: $MarqueeTagID, Deleted: $Deleted) {
        Status
	    Message
    }
}`

export const GetMarqueeTagAdmin = `query MyQuery($MarqueeTagID: Int!) {
    getMarqueeTagAdmin(MarqueeTagID: $MarqueeTagID) {
        MarqueeTagID
        Name
        Slug
        Status
        Deleted
        CreatedAt
        UpdatedAt
        PublishedAt
        MarqueeGroup
    }
}`